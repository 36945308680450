<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <h4>Order Reward Category</h4> 
            <sub-title>Change Order Sequence</sub-title> 
          </CCardHeader>
          <CCardBody>

                <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
                </CRow> 
                <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>

                <draggable v-model="data">
                    <transition-group>
                        <div v-for="element in data" :key="element.id">
                            {{element.viewOrder}} - {{element.name}} 
                        </div>
                    </transition-group>
                </draggable><br/>

                <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button v-on:click="actionGetData()" variant="primary">Save Order</b-button>  
                  </b-col>                  
                </b-row><br/>
              
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>  
</div>
</template> 

<script> 
 import RewardService from '@/api/RewardService.js';  
 import draggable from 'vuedraggable';
 
  export default { 
    name: 'view_order',
    components: { 
        draggable
    },     
    data() {
      return { 
          data:  [],         
          spinner:false,
          seen:false,
          msg :'',
          color:'',
          count:1
      };      
    },
    created () {                   
        this.getRewardCategoryList();
    },
    methods: {

      async actionGetData(){
          var jum = this.data.length; //console.log(jum);
          var newOrder = this.data;
          for (var key in newOrder) {  
             var row = newOrder[key];
             let odr = Number(key) + 1;   let param = {};
             param['name'] = row.name; 
             param['description'] = row.description; 
             param['viewOrder'] = odr; 
             const resp = await RewardService.updateRewardCategory(row.id, param);
             if(resp.message=='Success' && jum === 9){
                  this.msg   = 'Set Order Category Successfully'; 
                  this.color = 'success';  this.seen  = true; 
                  this.getRewardCategoryList();
                  setTimeout( () =>  this.seen=false , 5000); 
             }
          }

      }, 

      getRewardCategoryList: function() {
        RewardService.getRewardCategoryList().then(resp => {  //console.log(resp);
           resp.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));
           this.data = resp; 
        }, error => {
          this.spinner = false;
        });
      },

    }
  }
</script>